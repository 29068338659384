import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	constructor() {}

	set(key, object) {
		localStorage.setItem(key, JSON.stringify(object));
	}

	get(key) {
		return JSON.parse(localStorage.getItem(key));
	}

	remove(key) {
		localStorage.removeItem(key);
	}
}
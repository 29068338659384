import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { StorageService } from './storage.service';
import { classToPlain, plainToClass } from 'class-transformer';

import { User } from '../../models/user.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private user = new BehaviorSubject<User>(null);
	public showLoader: boolean = false;
	
	constructor(
		private http: HttpClient,
		private storage: StorageService,
	) {
		let user = this.storage.get('user');
		if (user) {
			this.user.next(plainToClass(User, user));
		}
	}

	login(data: any) {
		return this.http.post('/api/login', data).pipe(map(data => {
			let user = plainToClass(User, data);
			this.setCurrentUser(user);
			return user;
		}));
	}

	logout() {
		return this.http.get('/api/logout').pipe(map(data => {
			this.storage.remove('user');
			this.user.next(null);
		}));
	}

	register(data: any) {
		return this.http.post('/api/user', data).pipe(map(data => { 
			let user = plainToClass(User, data);
			this.setCurrentUser(user);
			return user;
		}));
	}

	setCurrentUser(user) {
		this.user.next(user);
		this.storage.set('user', user);
	}

	getCurrentUser() {
		return this.user;
	}

	getUser() {
		return this.http.get('/api/user').pipe(
			map(data => { 
				let user = plainToClass(User, data);
				this.setCurrentUser(user);
				return user;
			}),
			catchError((err: HttpErrorResponse) => {
				this.setCurrentUser(null);
				return throwError(err);
			})
		);
	}
}

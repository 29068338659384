import { Routes } from '@angular/router';

export const content: Routes = [
	{
		path: 'dashboard',
		loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
	},
	{
		path: 'estates',
		loadChildren: () => import('../../components/estates/estates.module').then(m => m.EstatesModule)
	},
	{
		path: 'users',
		loadChildren: () => import('../../components/users-admin/users-admin.module').then(m => m.UsersAdminModule)
	},
	{
		path: 'owners',
		loadChildren: () => import('../../components/owners/owners.module').then(m => m.OwnersModule)
	},
	{
		path: 'tenants',
		loadChildren: () => import('../../components/tenants/tenants.module').then(m => m.TenantsModule)
	},
	{
		path: 'expenses',
		loadChildren: () => import('../../components/expenses/expenses.module').then(m => m.ExpensesModule)
	},
	{
		path: 'contracts',
		loadChildren: () => import('../../components/contracts/contracts.module').then(m => m.ContractsModule)
	},
	{
		path: 'documents',
		loadChildren: () => import('../../components/upload-files/upload-files.module').then(m => m.UploadFilesModule)
	},
	{
		path: 'cashflows',
		loadChildren: () => import('../../components/cashflows/cashflows.module').then(m => m.CashflowsModule)
	}
	,
	{
		path: 'profile',
		loadChildren: () => import('../../components/profile/profile.module').then(m => m.ProfileModule)
	}
];

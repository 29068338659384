import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	constructor(private http: HttpClient) {}

	getDashboard() {
		return this.http.get('/api/dashboard',{ withCredentials: true });
	}

	getDashboardNotifications() {
		return this.http.get<any>('/api/dashboard-notifications',{ withCredentials: true });
	}

	getDashboardNotificationsRead() {
		return this.http.put('/api/dashboard-notified',{ withCredentials: true });
	}

}

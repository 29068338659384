<!-- Loader starts-->
<div class="loader-wrapper"[class.loderhide]="!show">
  <div class="loader-index"><span></span></div>
  <svg>
    <defs></defs>
    <filter id="goo">
      <fegaussianblur in="SourceGraphic" stddeviation="11" result="blur"></fegaussianblur>
      <fecolormatrix in="blur" values="0.49 0 0 0 0  0 0.78 0 0 0  0 0 0.36 0 0  0 0 0 1 0" result="goo"> </fecolormatrix>
    </filter>
  </svg>
</div>
<!-- Loader ends-->
      
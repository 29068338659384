import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
	selector: 'confirm',
	templateUrl: './confirm.component.html',
	styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
	@Input()
	public closeOnEvent: Subject<any>;

	@ViewChild('content', { static: true })
	private content;

	@Input()
	public disabled: boolean = false;

	@ViewChild('confirmModal', { static: true })
	private confirmModal: TemplateRef<any>;

	@Input('confirm-title')
	public title: string;

	@Input('confirm-body')
	public body: string;

	@Input('confirm-button')
	public confirm_text: string;

	@Input('cancel-button')
	public cancel_text: string;

	@Input()
	public type: string;

	private stopPropagation: boolean = true;
	private event: MouseEvent;
	private result: Subject<boolean> = new Subject<boolean>();
	private modalId: number;
	private modalRef: BsModalRef;
	private modalSub: Subscription;
	public processing: boolean;
	private subs: Subscription;

	constructor(private modalService: BsModalService) {}

	ngOnInit() {
		this.content.nativeElement.addEventListener(
			'click',
			(event: MouseEvent) => {
				if (this.stopPropagation && !this.disabled) {
					this.event = event;
					event.stopImmediatePropagation();
					this.askConfirmation();

					// si es un checkbox, prevengo el cambio de checked hasta que confirmo
					if (event.target['type'] == 'checkbox') event.target['checked'] = !event.target['checked'];
				}
			},
			true
		);
	}

	public action() {
		this.processing = true;

		// If a content event was captured, then dispatch it
		if (this.event) {
			this.stopPropagation = false;
			this.event.target.dispatchEvent(this.event);
			this.stopPropagation = true;

			// si es un checkbox, lo checkeo una vez que se ejecuta la accion
			if (this.event.target['type'] == 'checkbox') {
				this.event.target['checked'] = !this.event.target['checked'];
			}
		}

		// Should the modal wait for an external event to close?
		if (this.closeOnEvent) {
			this.subs = this.closeOnEvent.subscribe((event) => {
				this.onSuccess();
			});
		} else {
			this.onSuccess();
		}
	}

	public cancel() {
		if (this.processing) return;

		this.result.next(false);
		this.result.complete();
		this.result = null;

		this.closeModal();
	}

	private onSuccess() {
		this.processing = false;

		this.result.next(true);
		this.result.complete();
		this.result = null;

		this.closeModal();
	}

	public askConfirmation(): Observable<boolean> {
		this.result = new Subject<boolean>();
		this.openModal(this.confirmModal);

		return this.result;
	}

	private openModal(template: TemplateRef<any>, c: string = ''): void {
		this.setOnHideEvent();
		this.modalRef = this.modalService.show(template, { class: c });
	}

	private setOnHideEvent() {
		// Nested modals will also trigger the onHide event
		// Hack to identify the current modal
		// Future versions of ngx-bootstrap could include a modal id
		this.modalId = this.modalService.getModalsCount();
		this.modalSub = this.modalService.onHide.subscribe((reason: string) => {
			if (this.modalId == this.modalService.getModalsCount()) {
				this.modalId = null;
				this.modalSub.unsubscribe();
				// Reset all values
			}
		});
	}

	public closeModal() {
		if (this.subs) {
			this.subs.unsubscribe();
			this.subs = null;
		}
		this.modalRef.hide();
		this.modalRef = null;
	}
}

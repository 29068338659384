<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">

    </div>
    <div class="col-md-6">
      <p class="pull-right mb-0">Copyright {{ today | date:'y'}} © Bebrick all rights reserved.</p>
    </div>
  </div>
</div>
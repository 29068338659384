<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col-6">
        <h3>{{title}}</h3>
        <!--<ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <app-feather-icons [icon]="'grid'"></app-feather-icons>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">{{item}}</li>
          <!-- <li class="breadcrumb-item active">{{active_item}}</li> 
        </ol>-->
      </div>
      <div class="col-6">
        <!-- <app-bookmark></app-bookmark> -->
      </div>
    </div>
  </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'bebrick-form',
	templateUrl: './bebrick-form.component.html',
	styleUrls: ['./bebrick-form.component.scss']
})
export class BebrickFormComponent implements OnInit {
	@Input()
	public loading:boolean = false;
	
	@Input()
	public cancelLink:string;

	@Input()
	public formGroup:FormGroup;

	@Output()
	public onSubmit:EventEmitter<any> = new EventEmitter();
	
	constructor() {}

	ngOnInit() {}

	submit() {
		this.onSubmit.emit();
	}
}

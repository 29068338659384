<div class="container-fluid">
	<div class="row">
		<div class="col-sm-12">
			<div class="card">
				<div class="card-header">
					<!-- <h5>Basic Table</h5>
                    <span>Use a class<code>table</code> to any table.</span> -->
					<button class="btn btn-primary" [routerLink]="[addLink]" type="button">Agregar</button>
				</div>
				<div class="table-responsive" *ngIf="content && content.length">
					<ng-content></ng-content>
				</div>
				<div class="card-body" *ngIf="content && !content.length">
					No se encontraron datos
				</div>
			</div>
		</div>
	</div>
</div>
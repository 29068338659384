import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	roles?: Array<any>;
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	public megaMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMSADMIN: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		{
			title: 'Panel', icon: 'grid', type: 'link', active: true, path: '/dashboard'
		},
		{
			title: 'Propiedades', icon: 'home', type: 'link', active: false, path: '/estates'
		},
		{
			title: 'Sociedades', icon: 'trending-up', type: 'link', active: false, path: '/owners'
		},
		{
			title: 'Inquilinos', icon: 'users', type: 'link', active: false, path: '/tenants'
		},
		{
			title: 'Contratos', icon: 'pen-tool', type: 'link', active: false, path: '/contracts'
		},
		{
			title: 'Ingresos/Egresos Mensuales', icon: 'dollar-sign', type: 'link', active: false, path: '/expenses'
		},
		{
			title: 'CashFlows', icon: 'trending-up', type: 'link', active: false, path: '/cashflows'
		},
		{
			title: 'Administradores', icon: 'users', type: 'link', active: false, path: '/users'
		},
		
	];

	MENUITEMSOWNER: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		{
			title: 'Mis datos', icon: 'grid', type: 'link', active: true, path: '/profile'
		},
		{
			title: 'Propiedades', icon: 'home', type: 'link', active: false, path: '/estates'
		}
		
	];

	MENUITEMSTENANT: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		{
			title: 'Mis datos', icon: 'home', type: 'link', active: false, path: '/profile'
		},
		
	];

	public itemsByUser(role){
		if(role == 'admin'){
			return new BehaviorSubject<Menu[]>(this.MENUITEMSADMIN);
		}
		if(role == 'owner'){
			return new BehaviorSubject<Menu[]>(this.MENUITEMSOWNER);
		}
		if(role == 'tenant'){
			return new BehaviorSubject<Menu[]>(this.MENUITEMSTENANT);
		}
		
	}


	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMSADMIN);

}

<span #content class="content">
	<ng-content></ng-content>
</span>

<ng-template #confirmModal>
	<!-- modal -->
	<div class="modal-header">
		<h5 class="modal-title" *ngIf="title" [class.text-danger]="type == 'danger'">{{ title }}</h5>
		<button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" *ngIf="body" [innerHTML]="body"></div>
	<div class="modal-footer d-block">
		<div class="row no-gutters">
			<div class="col">
				<button
					*ngIf="cancel_text"
					(click)="closeModal()"
					[disabled]="processing"
					type="button"
					class="btn btn-outline-primary"
					data-dismiss="modal"
				>
					{{ cancel_text }}
				</button>
			</div>
			<div class="col text-right">
				<button
					*ngIf="confirm_text"
					(click)="action()"
					[disabled]="processing"
					type="button"
					class="btn"
					[class.btn-primary]="!type"
					[class.btn-danger]="type == 'danger'"
				>
					{{ confirm_text }}
				</button>
			</div>
		</div>
	</div>
</ng-template>

import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpResponse,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HTTP_INTERCEPTORS,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { of, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap, tap } from 'rxjs/internal/operators';
import { ComponentCommService } from './shared/services/component-comm.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AuthService } from './shared/services/auth.service';
import { User } from './models/user.model';

@Injectable()
export class ApiConfInterceptor implements HttpInterceptor {
	private user:User;
	private silentUrls = [
		/\/api\/videos\/[0-9]\/actions/
	];
    
	constructor(
		public comm: ComponentCommService,
		public toasterService: ToastrService,
		public authService: AuthService
	) {
        authService.getCurrentUser().subscribe(user => {
            this.user = user;
        });
    }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const isSilent = this.isSilentUrl(request.url);

		if (!isSilent) {
			this.comm.emit('loading');
		}

		var req: HttpRequest<any>;
        let headers = {};
        
        if(this.user) {
            headers['Authorization'] = `Bearer ${this.user.token}`;
        }

		req = request.clone({
			url: request.url.replace('/api/', environment.apiBaseUrl),
			setHeaders: headers
        });

		return next.handle(req).pipe(
			tap((evt) => {
				if (evt instanceof HttpResponse && !isSilent) {
					this.comm.emit('loaded');
				}
			}),
			catchError((err: HttpErrorResponse) => {
				if (!isSilent) {
					this.comm.emit('loaded');
				}
				if (err.status.toString().startsWith('5')) {
					try {
						this.toasterService.error(err.error.message, 'Error', { positionClass: 'toast-top-right' });
					} catch (e) {
						this.toasterService.error('An error occurred', 'Error', { positionClass: 'toast-top-right' });
					}
				}
				return throwError(err);
			})
		);
	}

	private isSilentUrl(url:string) {
		for (let i = 0; i < this.silentUrls.length; i++) {
			if (this.silentUrls[i].exec(url)) return true;
		}

		return false;
	}
}

export let ApiProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: ApiConfInterceptor,
	multi: true
};

import { Token } from './token.model';
import { dateToString, stringToDate } from '../transforms';
import { Transform } from "class-transformer";
import { Estate } from './estate.model';

export class User {
    id?: number;
    first_name: string;
    last_name: string;
    email: string;
    token: Token;
    roles: string[];
    company_name: string;
    gender: string;
    unique_identifier: string;
    address: string;
    phone_number: string;
    deletable: boolean;
    estates: Estate;

    @Transform(dateToString, { toPlainOnly: true })
    @Transform(stringToDate, { toClassOnly: true })
    created_at?: Date;

    get isAdmin() {
        return this.hasRoles(['admin']);
    }

    hasRoles(roles) {
        return roles ? roles.every(i => this.roles.includes(i)) : true;
    }
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
	@Input()
	public content:any[];

	@Input()
	public addLink:string;

	@Input()
	public role:string;
	
	constructor() {}

	ngOnInit() {}
}

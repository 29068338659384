import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ComponentCommService {
	public events = new EventEmitter();
	
	constructor() {}

	public emit (value?) {
		this.events.emit(value ? value : undefined);
	}
}

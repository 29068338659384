import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AdminGuard implements CanActivate {
	constructor(public authService: AuthService, public router: Router) {
		this.authService.getUser().subscribe(() => {}, (err) => {
			this.router.navigate(['/auth/login']);
		});
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return new Observable(observer => {
			this.authService.getCurrentUser().subscribe((user) => {
				if (user) {
					observer.next(true);
					
				} else {
					observer.next(false);
					this.router.navigate(['/auth/login']);
				}
			});
		});
	}
}

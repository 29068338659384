import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {
	transform(content: any[], key: any, value: any): any {
		return content.filter(item => {
			return item[key] == value;
		})[0];
	}
}

<div class="container-fluid">
	<div class="row">
		<div class="col-sm-12">
			<div class="card">
				<form [formGroup]="formGroup" (ngSubmit)="submit()" autocomplete="off">
					<div class="card-body">
						<ng-content></ng-content>
					</div>
					<div class="card-footer">
						<div class="row">
							<div class="col">
								<button
									type="button"
									class="btn cur-p btn-outline-secondary"
									[routerLink]="[cancelLink]"
								>
									Cancelar
								</button>
							</div>
							<div class="col text-right">
								<button type="submit" class="btn btn-primary" [disabled]="loading">Guardar</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// import { AuthService } from '../../shared/services/firebase/auth.service';
import { AuthService } from '../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public newUser = false;
	public signup: boolean;
	public user: any; //firebase.User;
	public loginForm: FormGroup;
	public registrationForm: FormGroup;
	public registrationFormSubmitted:boolean = false;

	public formErrors: FormErrors = {
		email: '',
		password: ''
	};

	public errorMessage: any;

	constructor(
		public authService: AuthService,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		public toaster: ToastrService,
		private router: Router
	) {
		const url = this.route.snapshot.url;
		if (url[0].path === 'simple-login') {
			this.signup = false;
		}

		this.registrationForm = fb.group({
			// mobileNumber: [null, [Validators.required]]
			first_name: ['', [Validators.required]],
			last_name: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required]]
		});
		this.loginForm = fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required]]
		});
	}

	// convenience getter for easy access to form fields
    get f() { return this.registrationForm.controls; }

	ngOnInit() {}

	public toggle() {
		this.signup = !this.signup;
	}

	// Login With Google
	loginGoogle() {
		// this.authService.GoogleAuth();
	}

	// Login With Twitter
	loginTwitter(): void {
		// this.authService.signInTwitter();
	}

	// Login With Facebook
	loginFacebook() {
		// this.authService.signInFacebok();
	}

	// Simple Login
	login() {
		this.authService.showLoader = true;
		let data = {
			email: this.loginForm.value['email'],
			password: this.loginForm.value['password']
		};
		this.authService.login(data).subscribe(
			(response) => {
				this.authService.showLoader = false;
				if(response.roles[0] != 'admin'){
					this.router.navigate(['/profile']);
				} else {
					this.router.navigate(['/']);
				}
			},
			(error) => {
				this.authService.showLoader = false;
				this.toaster.error('You have enter Wrong Email or Password.');
			}
		);
		// this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
	}

	register() {
		this.registrationFormSubmitted = true;
		this.authService.showLoader = true;

		let data = {
			first_name: this.registrationForm.value['first_name'],
			last_name: this.registrationForm.value['last_name'],
			email: this.registrationForm.value['email'],
			password: this.registrationForm.value['password'],
			password_confirmation: this.registrationForm.value['password'],
		};

		this.authService.register(data).subscribe((response) => {
			this.router.navigate(['/']);
			this.authService.showLoader = false;
		}, (response) => {
			this.authService.showLoader = false;
			Object.keys(response.error.errors).forEach((key) => {
				this.registrationForm.controls[key].setErrors({
					"custom": response.error.errors[key][0]
				});
			});
		});
	}
}

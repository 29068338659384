<!-- page-wrapper Start-->
<div class="container-fluid p-0">
	<!-- login page start-->
	<div class="authentication-main">
		<div class="row">
			<div class="col-md-12">
				<div class="auth-innerright">
					<div class="authentication-box">
						<div>
							<div class="card-body p-0">
								<div class="cont text-center b-light" [ngClass]="{ 's--signup': signup }">
									<div>
										<form
											class="theme-form"
											[formGroup]="loginForm"
											*ngIf="!newUser"
											(ngSubmit)="login()"
											autocomplete="off"
										>
											<h4>LOGIN</h4>
											<h6>Enter your Username and Password</h6>
											<div class="form-group">
												<label class="col-form-label pt-0">Your Name</label>
												<input
													class="form-control"
													type="text"
													required=""
													formControlName="email"
												/>
												<div
													*ngIf="
														loginForm.controls.email.touched &&
														loginForm.controls.email.errors?.required
													"
													class="text text-danger mt-1"
												>
													Email is required
												</div>
												<div
													*ngIf="
														loginForm.controls.email.touched &&
														loginForm.controls.email.errors?.email
													"
													class="text text-danger mt-1"
												>
													Invalid Email
												</div>
											</div>
											<div class="form-group">
												<label class="col-form-label">Password</label>
												<input
													class="form-control"
													type="password"
													required=""
													formControlName="password"
												/>
												<div
													*ngIf="
														loginForm.controls.password.touched &&
														loginForm.controls.password.errors?.required
													"
													class="text text-danger mt-1"
												>
													Password is required
												</div>
											</div>
											<div class="form-group form-row mt-3 mb-0">
												<button
													class="btn btn-primary btn-block"
													[class.loader--text]="authService.showLoader"
													[disabled]="!loginForm.valid || authService.showLoader"
													type="submit"
												>
													<span>{{ authService.showLoader ? '' : 'Login' }}</span>
												</button>
											</div>
											<!-- <div class="login-divider"></div>
                                            <div class="social mt-3">
                                                <div class="form-row btn-showcase">
                                                    <div class="col-md-4 col-sm-6">
                                                        <button class="btn social-btn btn-fb" (click)="loginFacebook()">Facebook</button>
                                                    </div>
                                                    <div class="col-md-4 col-sm-6">
                                                        <button class="btn social-btn btn-twitter btn-block" (click)="loginTwitter()">Twitter</button>
                                                    </div>
                                                    <div class="col-md-4 col-sm-6">
                                                        <button class="btn social-btn btn-google btn-block" (click)="loginGoogle()">
                                                            Google +
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> -->
										</form>
									</div>
									<div class="sub-cont">
										<div class="img">
											<div class="img__text m--up">
												<h2>New User?</h2>
												<p>Sign up and discover great amount of new opportunities!</p>
											</div>
											<div class="img__text m--in">
												<h2>One of us?</h2>
												<p>If you already has an account, just sign in. We've missed you!</p>
											</div>
											<div class="img__btn" (click)="toggle()">
												<span class="m--up">Sign up</span><span class="m--in">Sign in</span>
											</div>
										</div>
										<div>
                                            <form
                                            	class="theme-form"
												[formGroup]="registrationForm"
												(ngSubmit)="register()"
												autocomplete="off"
											>
												<h4 class="text-center">NEW USER</h4>
												<h6 class="text-center">Enter your Username and Password For Signup</h6>
												<div class="form-row">
													<div class="col-md-12">
														<div class="form-group">
															<input
																class="form-control"
																type="text"
																placeholder="First Name"
																formControlName="first_name"
                                                                required=""
															/>
															<div
																*ngIf="
																	registrationForm.controls.first_name.touched &&
																	registrationForm.controls.first_name.errors?.required
																"
																class="text text-danger mt-1"
															>
																First name is required
															</div>
														</div>
													</div>
													<div class="col-md-12">
														<div class="form-group">
															<input
																class="form-control"
																type="text"
																placeholder="Last Name"
																formControlName="last_name"
                                                                required=""
                                                            />
                                                            <div
																*ngIf="
																	registrationForm.controls.last_name.touched &&
																	registrationForm.controls.last_name.errors?.required
																"
																class="text text-danger mt-1"
															>
																Last name is required
															</div>
														</div>
													</div>
												</div>
												<div class="form-group">
													<input
														class="form-control"
														type="text"
														placeholder="Email"
                                                        formControlName="email"
                                                        required=""
													/>
													<div
														*ngIf="registrationFormSubmitted"
														class="text text-danger mt-1"
													>
														<span *ngIf="f.email.errors?.custom">{{ f.email.errors?.custom }}</span>
													</div>
												</div>
												<div class="form-group">
													<input
														class="form-control"
														type="password"
														placeholder="Password"
                                                        formControlName="password"
                                                        required=""
													/>
												</div>
												<div class="form-row">
													<div class="col-sm-4">
                                                        <button 
                                                            class="btn btn-primary" 
                                                            [class.loader--text]="authService.showLoader"
                                                            [disabled]="!registrationForm.valid || authService.showLoader"
                                                            type="submit"
                                                        >
                                                        <span>{{ authService.showLoader ? '' : 'Sign Up' }}</span>
                                                        </button>
													</div>
													<div class="col-sm-8">
														<div class="text-left mt-2 m-l-20">
															Are you already user?  <a
																class="btn-link text-capitalize"
																(click)="toggle()"
																href="javascript:void(0)"
																>Login</a
															>
														</div>
													</div>
												</div>
												<!-- <div class="form-divider"></div>
                                                <div class="social mt-3">
                                                    <div class="form-row btn-showcase">
                                                        <div class="col-sm-4">
                                                            <button class="btn social-btn btn-fb">Facebook</button>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <button
                                                                class="btn social-btn btn-twitter btn-block">Twitter</button>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <button class="btn social-btn btn-google btn-block">Google
                                                                +</button>
                                                        </div>
                                                    </div>
                                                </div> -->
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- login page end-->
</div>

<!-- Page Header Start-->
<div class="page-main-header" [class.close_icon]="navServices.collapseSidebar">
	<div class="main-header-right row m-0">
		<form class="form-inline search-full" [class.open]="search">
			<div class="form-group w-100">
				<input
					class="form-control-plaintext"
					[(ngModel)]="text"
					[ngModelOptions]="{ standalone: true }"
					(keyup)="searchTerm(text)"
					type="text"
					autocomplete="off"
					placeholder="Search.."
				/>
				<app-feather-icons [icon]="'x'" class="close-search" (click)="searchToggle()"></app-feather-icons>
				<div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
					<div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice: 0:8">
						<div class="ProfileCard-avatar">
							<app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
						</div>
						<div class="ProfileCard-details">
							<div class="ProfileCard-realName">
								<a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{
									menuItem?.title
								}}</a>
							</div>
						</div>
					</div>
				</div>
				<div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
					<div class="tt-dataset tt-dataset-0">
						<div class="EmptyMessage">Opps!! There are no result found.</div>
					</div>
				</div>
			</div>
		</form>
		<div class="main-header-left">
			<div class="logo-wrapper">
				<a routerLink="/">
					<img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
					<img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" />
				</a>
			</div>
			<div class="toggle-sidebar" (click)="sidebarToggle()">
				<app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
			</div>
		</div>
		<div class="left-menu-header col horizontal-wrapper pl-0"></div>
		<div class="nav-right col-8 pull-right right-menu">
			<ul class="nav-menus">
				<!-- 
				<li class="language-nav" (click)="languageToggle()">
					<div class="translate_wrapper" [class.active]="language">
						<div class="current_lang">
						<div class="lang">
							<i class="flag-icon flag-icon-{{selectedLanguage.icon}}"></i><span
							class="lang-txt">{{selectedLanguage.code}}</span>
						</div>
						</div>
						<div class="more_lang" [class.active]="language">
						<div class="lang selected" data-value="en" (click)="changeLanguage(lang)" *ngFor="let lang of languages">
							<i class="flag-icon flag-icon-{{lang.icon}}"></i>
							<span class="lang-txt">{{lang.language}}<span *ngIf="lang.type">({{lang.type}})</span></span>
						</div>
						</div>
					</div>
				</li> 
				-->
				<!-- <li>
					<span class="header-search" (click)="searchToggle()">
						<app-feather-icons [icon]="'search'"></app-feather-icons>
					</span>
				</li> -->
				<li class="onhover-dropdown">
					<div class="notification-box">
						<app-feather-icons [icon]="'bell'"></app-feather-icons>
						<span class="badge badge-pill badge-secondary">{{ contracts.length }}</span>
					</div>
					<ul class="notification-dropdown onhover-show-div">
						<li class="bg-primary">
							<h6 class="f-18 mb-0">Notiticationes</h6>
							<p class="mb-0">Tiene {{ contracts.length }} notiticationes</p>
						</li>
						<li *ngFor="let contract of contracts">
							<p class="mb-0">
								<i class="fa fa-circle-o mr-3 font-danger"> </i>{{ contract.title }} expira el {{ contract.expiration_date }}
							</p>
						</li>
						<li>
							<a class="btn btn-primary" (click)="clearNotifications()">Marcar como leidas</a>
						</li>
					</ul>
				</li>
				<li>
					<div class="mode" (click)="layoutToggle()">
						<i class="fa fa-moon-o" *ngIf="!dark"></i>
						<i class="fa fa-lightbulb-o" *ngIf="dark"></i>
					</div>
				</li>
				<li class="maximize">
					<a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
						<app-feather-icons [icon]="'maximize'"></app-feather-icons>
					</a>
				</li>
				<li class="profile-nav onhover-dropdown p-0">
					<div class="media profile-media">
						<!-- <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="" /> -->
						<div class="media-body">
							<span>{{ user.first_name }} {{ user.last_name }}</span>
							<p class="mb-0 font-roboto"> <i class="middle fa fa-angle-down"></i></p>
						</div>
					</div>
					<ul class="profile-dropdown onhover-show-div">
						<!-- <li><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></li>
						<li><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></li>
						<li><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></li>
						<li><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></li> -->
						<li (click)="logout()"><app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</div>
<!-- Page Header Ends -->
